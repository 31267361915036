// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateUserRequest from "./requests/UpdateUserRequest.res.js";
import * as MapRpmAgentToUserRequest from "./requests/MapRpmAgentToUserRequest.res.js";
import * as FetchUserDashboardRequest from "./requests/FetchUserDashboardRequest.res.js";
import * as UserChangePasswordRequest from "./requests/UserChangePasswordRequest.res.js";
import * as FetchUsersDashboardRequest from "./requests/FetchUsersDashboardRequest.res.js";
import * as UpdateUserRoleDashboardRequest from "./requests/UpdateUserRoleDashboardRequest.res.js";
import * as UserDashboardUpdateUserRequest from "./requests/UserDashboardUpdateUserRequest.res.js";
import * as UpdateDailyLeadsDashboardRequest from "./requests/UpdateDailyLeadsDashboardRequest.res.js";
import * as UpdateUserApprovedDashboardRequest from "./requests/UpdateUserApprovedDashboardRequest.res.js";
import * as UpdateUserApiEnabledDashboardRequest from "./requests/UpdateUserApiEnabledDashboardRequest.res.js";
import * as UpdateUserSignupDistributionDashboardRequest from "./requests/UpdateUserSignupDistributionDashboardRequest.res.js";

var dashboard = FetchUsersDashboardRequest.exec;

var fetchUserDashboard = FetchUserDashboardRequest.exec;

var updateApprovedDashboard = UpdateUserApprovedDashboardRequest.exec;

var updateApiEnabledDashboard = UpdateUserApiEnabledDashboardRequest.exec;

var updateRoleDashboard = UpdateUserRoleDashboardRequest.exec;

var updateDailyLeadsDashboard = UpdateDailyLeadsDashboardRequest.exec;

var updateSignupDistributionDashboard = UpdateUserSignupDistributionDashboardRequest.exec;

var update = UpdateUserRequest.exec;

var updateDashboard = UserDashboardUpdateUserRequest.exec;

var mapRpmAgencies = MapRpmAgentToUserRequest.exec;

var changePassword = UserChangePasswordRequest.exec;

export {
  dashboard ,
  fetchUserDashboard ,
  updateApprovedDashboard ,
  updateApiEnabledDashboard ,
  updateRoleDashboard ,
  updateDailyLeadsDashboard ,
  updateSignupDistributionDashboard ,
  update ,
  updateDashboard ,
  mapRpmAgencies ,
  changePassword ,
}
/* UpdateUserRequest Not a pure module */
