// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Api_User from "../../../api/users/Api_User.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as $$Notification from "../../../bindings/Notification.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.res.js";
import * as ReactNotifications from "react-notifications";
import * as ChangePasswordScss from "./ChangePassword.scss";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";

var css = ChangePasswordScss;

var validators_password = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (param) {
      var password = param.password;
      if (password === "") {
        return {
                TAG: "Error",
                _0: "Password is required"
              };
      } else if (password.length < 8) {
        return {
                TAG: "Error",
                _0: String(8) + " + characters, please"
              };
      } else {
        return {
                TAG: "Ok",
                _0: password
              };
      }
    })
};

var validators_confirmPassword = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (param) {
      var confirmPassword = param.confirmPassword;
      if (confirmPassword === "") {
        return {
                TAG: "Error",
                _0: "Confirmation is required"
              };
      } else if (confirmPassword !== param.password) {
        return {
                TAG: "Error",
                _0: "Password doesn't match"
              };
      } else {
        return {
                TAG: "Ok",
                _0: confirmPassword
              };
      }
    })
};

var validators = {
  password: validators_password,
  confirmPassword: validators_confirmPassword
};

function initialFieldsStatuses(_input) {
  return {
          password: "Pristine",
          confirmPassword: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            password: "Pristine",
            confirmPassword: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.password;
  var tmp;
  tmp = typeof match !== "object" ? validators.password.validate(input) : match._0;
  var match$1 = fieldsStatuses.confirmPassword;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.confirmPassword.validate(input) : match$1._0;
  var passwordResult = tmp;
  var passwordResult$1;
  if (passwordResult.TAG === "Ok") {
    var confirmPasswordResult = tmp$1;
    if (confirmPasswordResult.TAG === "Ok") {
      return {
              TAG: "Valid",
              output: {
                password: passwordResult._0,
                confirmPassword: confirmPasswordResult._0
              },
              fieldsStatuses: {
                password: {
                  TAG: "Dirty",
                  _0: passwordResult,
                  _1: "Shown"
                },
                confirmPassword: {
                  TAG: "Dirty",
                  _0: confirmPasswordResult,
                  _1: "Shown"
                }
              },
              collectionsStatuses: undefined
            };
    }
    passwordResult$1 = passwordResult;
  } else {
    passwordResult$1 = passwordResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            password: {
              TAG: "Dirty",
              _0: passwordResult$1,
              _1: "Shown"
            },
            confirmPassword: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurPasswordField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.password, validators_password, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  password: status,
                                  confirmPassword: init.confirmPassword
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfirmPasswordField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.confirmPassword, validators_confirmPassword, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  password: init.password,
                                  confirmPassword: status
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdatePasswordField" :
                  var nextInput = action._0(state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$2 = Formality.validateDependentFieldOnChange(nextInput, nextFieldsStatuses.contents.confirmPassword, validators_confirmPassword, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  password: init.password,
                                  confirmPassword: status
                                };
                        }));
                  if (result$2 !== undefined) {
                    nextFieldsStatuses.contents = result$2;
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, nextFieldsStatuses.contents.password, state.submissionStatus, validators_password, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            password: status,
                                            confirmPassword: init.confirmPassword
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfirmPasswordField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.confirmPassword, state.submissionStatus, validators_confirmPassword, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            password: init.password,
                                            confirmPassword: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                password: "Pristine",
                                confirmPassword: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                password: "Pristine",
                                confirmPassword: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updatePassword: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdatePasswordField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateConfirmPassword: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfirmPasswordField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurPassword: (function () {
              dispatch("BlurPasswordField");
            }),
          blurConfirmPassword: (function () {
              dispatch("BlurConfirmPasswordField");
            }),
          passwordResult: Formality.exposeFieldResult(state.fieldsStatuses.password),
          confirmPasswordResult: Formality.exposeFieldResult(state.fieldsStatuses.confirmPassword),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.password;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.confirmPassword;
              if (typeof tmp$1 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var ChangePasswordComponentForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function UserChangePassword$default(props) {
  var initialInput = React.useMemo((function () {
          return {
                  password: "",
                  confirmPassword: ""
                };
        }), []);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var getResetPasswordToken = function () {
    var urlSearch = url.search;
    return Belt_Array.get(urlSearch.split("="), 1);
  };
  var form = useForm(initialInput, (function (output, cb) {
          var token = getResetPasswordToken();
          $$Promise.wait(Api_User.changePassword({
                    user: {
                      password: output.password.trim(),
                      confirmPassword: output.confirmPassword.trim(),
                      resetPasswordToken: token
                    }
                  }, "/users/password"), (function (x) {
                  if (x.TAG === "Ok") {
                    $$Notification.NotificationManager.success("Password instructions sent successfully", "", 5000);
                    setTimeout((function () {
                            Url.visit("/users/sign_in");
                          }), 1000);
                    return ;
                  }
                  $$Notification.NotificationManager.error("Something went wrong", "", 5000);
                  setTimeout(cb.reset, 1000);
                }));
        }));
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ReactNotifications.NotificationContainer, {}),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs("form", {
                                        children: [
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsxs("h2", {
                                                      children: [
                                                        "Change your",
                                                        JsxRuntime.jsx("span", {
                                                              children: " Password",
                                                              className: "font-weight-bold"
                                                            })
                                                      ],
                                                      className: ""
                                                    }),
                                                className: "mb-2"
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsxs("p", {
                                                      children: [
                                                        "Need an account?",
                                                        JsxRuntime.jsx("a", {
                                                              children: "Sign Up",
                                                              href: "/users/sign_up"
                                                            })
                                                      ],
                                                      className: Cx.cx([
                                                            css.gap1,
                                                            css.flex
                                                          ])
                                                    })
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Label.make, {
                                                        forId: "password",
                                                        children: "New Password"
                                                      }),
                                                  JsxRuntime.jsx(TextField.make, {
                                                        id: "password",
                                                        type_: "Password",
                                                        value: form.input.password,
                                                        placeholder: "Enter your new password",
                                                        onBlur: (function (param) {
                                                            form.blurPassword();
                                                          }),
                                                        onChange: (function ($$event) {
                                                            form.updatePassword((function (input, value) {
                                                                    return {
                                                                            password: value,
                                                                            confirmPassword: input.confirmPassword
                                                                          };
                                                                  }), $$event.target.value);
                                                          })
                                                      }),
                                                  fieldError(form.passwordResult)
                                                ],
                                                className: css.field
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Label.make, {
                                                        forId: "confirm_password",
                                                        children: "Confirm password"
                                                      }),
                                                  JsxRuntime.jsx(TextField.make, {
                                                        id: "confirm_password",
                                                        type_: "Password",
                                                        value: form.input.confirmPassword,
                                                        placeholder: "Confirm your new password",
                                                        onBlur: (function (param) {
                                                            form.blurConfirmPassword();
                                                          }),
                                                        onChange: (function ($$event) {
                                                            form.updateConfirmPassword((function (input, value) {
                                                                    return {
                                                                            password: input.password,
                                                                            confirmPassword: value
                                                                          };
                                                                  }), $$event.target.value);
                                                          })
                                                      }),
                                                  fieldError(form.confirmPasswordResult)
                                                ],
                                                className: css.field
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx(Button.make, {
                                                            size: "MD",
                                                            color: "Orange",
                                                            disabled: form.submitting,
                                                            busy: form.submitting,
                                                            submit: true,
                                                            children: "Change my Password"
                                                          })
                                                    }),
                                                className: css.flexContainer
                                              })
                                        ],
                                        className: css.formContainer,
                                        onSubmit: (function ($$event) {
                                            $$event.preventDefault();
                                            form.submit();
                                          })
                                      }),
                                  className: ""
                                }),
                            className: ""
                          }),
                      className: css.container
                    })
              ],
              className: css.changePasswordContainer
            });
}

var $$default = UserChangePassword$default;

export {
  css ,
  ChangePasswordComponentForm ,
  $$default as default,
}
/* css Not a pure module */
