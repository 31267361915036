// extracted by mini-css-extract-plugin
export var changePasswordContainer = "ChangePassword__changePasswordContainer__Y9gjf";
export var column = "ChangePassword__column__hRmjo";
export var container = "ChangePassword__container__DnAQC";
export var error = "ChangePassword__error__ffZHs";
export var flex = "ChangePassword__flex__T_DK7";
export var flexColumn = "ChangePassword__flexColumn__s0kp8";
export var flexContainer = "ChangePassword__flexContainer__fSsLE";
export var formContainer = "ChangePassword__formContainer__sBE1O";
export var gap1 = "ChangePassword__gap1__CVNEA";
export var gap2 = "ChangePassword__gap2__nNYe1";
export var gap3 = "ChangePassword__gap3__bkXOE";
export var gap4 = "ChangePassword__gap4__F0HnT";
export var gap5 = "ChangePassword__gap5__iCWYc";
export var linkMuted = "ChangePassword__linkMuted__zuPdv";
export var row = "ChangePassword__row__ZHHvG";
export var smallText = "ChangePassword__smallText__DsIg8";