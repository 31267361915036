// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function userToJson(user) {
  return Json_Encode$JsonCombinators.object([
              [
                "password",
                user.password
              ],
              [
                "confirm_password",
                user.confirmPassword
              ],
              [
                "reset_password_token",
                Belt_Option.getWithDefault(user.resetPasswordToken, "")
              ]
            ]);
}

function toJson(req) {
  return Json_Encode$JsonCombinators.object([[
                "user",
                userToJson(req.user)
              ]]);
}

var Req = {
  userToJson: userToJson,
  toJson: toJson
};

function exec(body, changePasswordPath) {
  return Rest.fetchRoot(changePasswordPath, {
              NAME: "Put",
              VAL: toJson(body)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
